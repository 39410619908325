import dayjs from "dayjs"
import { withPrefix } from "gatsby"

const DEBUG_DATE = null
// const DEBUG_DATE = "2024-05-10 08:00"
// const DEBUG_DATE = "2024-04-15 18:00"
if (DEBUG_DATE) console.warn("DEBUG DATE", DEBUG_DATE)

let currentShadeIndex = -1

class Util {

  static resetShade = () => {
    currentShadeIndex = -1
  }

  static getShade = (
    visible,
    predefined = "none",
    shadeVariants = [
      "none",
      "dark",
      // "light"
    ]
  ) => {
    if (!visible) return

    if (currentShadeIndex < shadeVariants.length - 1) {
      currentShadeIndex++
    } else {
      currentShadeIndex = 0
    }

    return shadeVariants[currentShadeIndex]

    // return predefined
  }

  /**
   * Encode the string using cyrb53 algo - not a real hash but it ensure enough uniqueness with shorter hashes for most uses
   * @param {string} str 
   * @param {integer} seed 
   * @returns string
   */
  static cyrb53 = (str, seed = 0) => {
    let h1 = 0xdeadbeef ^ seed, h2 = 0x41c6ce57 ^ seed
    for (let i = 0, ch; i < str.length; i++) {
      ch = str.charCodeAt(i)
      h1 = Math.imul(h1 ^ ch, 2654435761)
      h2 = Math.imul(h2 ^ ch, 1597334677)
    }
    h1 = Math.imul(h1 ^ (h1 >>> 16), 2246822507) ^ Math.imul(h2 ^ (h2 >>> 13), 3266489909)
    h2 = Math.imul(h2 ^ (h2 >>> 16), 2246822507) ^ Math.imul(h1 ^ (h1 >>> 13), 3266489909)
    return "" + (4294967296 * (2097151 & h2) + (h1 >>> 0))
  }

  /**
   * Sorts array of JSON objects per given key and direction
   * @param {object[]} data 
   * @param {string} key 
   * @param {string} direction - [asc] | desc
   * @returns json
   */
  static sortJSON = (data, key, direction = "asc") => {
    const directionFactor = direction === "asc" ? 1 : -1
    return [...data].sort((a, b) => {
      if (typeof a[key] === "string") {
        return a[key].localeCompare(b[key]) * directionFactor
      } else {
        return (a[key] < b[key] ? -1 : 1) * directionFactor
      }
    })
  }

  /**
   * Sorts array of JSON objects per multiple keys and directions
   * @param {object[]} data 
   * @param {string[]} keys 
   * @returns 
   */
  static sortJSONByMultiKey = (data, keys, logSteps = false) => {
    return [...data].sort((a, b) => {
      if (logSteps) console.log("compare", a, b)
      for (let i = 0; i < keys.length; i++) {
        const key = keys[i].key
        const directionFactor = keys[i].direction === "asc" ? 1 : -1

        let res =
          (typeof a[key] === "string")
            ? a[key].localeCompare(b[key]) * directionFactor
            : (a[key] < b[key] ? -1 : 1) * directionFactor

        if (logSteps) console.log("key", keys[i].key, keys[i].direction, res ? res : "[skipping]")

        if (res) return res // important - matching values are ignored allowing next key to be evaluated
      }
      return 0 // ensure that matched values are finally resolved, otherwise sorting function is not complete
    })
  }

  /**
   * Removes duplicates of the given key in object
   * @param {json} data 
   * @param {string} key 
   * @returns json
   */
  static removeDuplicatesJSON = (data, key) => {
    var result = data.reduce((unique, o) => {
      if (!unique.some(obj => obj[key] === o[key])) {
        unique.push(o)
      }
      return unique
    }, [])
    return result
  }

  /**
   * Ensures floating header top position after page is scrolled
   * @param {object} document 
   * @param {object} window 
   * @param {number} scrollPos 
   * @returns 
   */
  static handleHeaderScroll = (document, window, scrollPos) => { // not used currently
    let result = 0

    let headers = document.getElementsByClassName("pageHeader")
    if (headers.length > 0) {
      let hh = headers[0].offsetHeight
      let deltaY = window.scrollY - scrollPos
      if (window.scrollY > hh && deltaY > 0) {
        if (headers[0].style.top === "0px") {
          headers[0].style.top = `-${hh + 10}px` // hide
        }
      } else {
        headers[0].style.top = 0 // show
      }

      result = window.scrollY
    }

    return result
  }

  /**
   * Check if e-mail address is valid
   * @param {string} email 
   * @returns boolean
   */
  static isValidEMail = (email) => {
    let re = /^([a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?)$/gm
    return re.test(email)
  }

  /**
   * Check if PIN is valid
   * @param {string} email 
   * @returns boolean
   */
  static isValidPIN = (pin, expectedLength = 4) => {
    let re = new RegExp(`^\\d{${expectedLength},${expectedLength}}$`, "g")
    return re.test(pin)
  }

  /**
   * Check if phone is valid
   * @param {string} phone 
   * @returns boolean
   */
  static isValidPhone = (phone) => {
    let re = /^\s*(?:\+?(\d{1,3}))?([-. (]*(\d{2,3})[-. )]*)?((\d{3})[-. ]*(\d{2,4})(?:[-.x ]*(\d+))?)\s*$/gm // regex from regexr.com (template by Alex Snet) - forked

    return re.test(phone)
  }

  /**
   * Checks if given value is really empty (checking for all JS quirks)
   * @param {any} value 
   * @param {boolean} trimString 
   * @returns boolean
   */
  static isEmpty = (value, trimString = false) => {
    return (
      (value == null) ||
      (typeof value === "string" && (trimString ? (value.trim() === "") : (value === ""))) ||
      (value.size === 0) ||
      (value.length === 0 && typeof value !== "function") ||
      (value.constructor === Object && Object.keys(value).length === 0)
    )
  }

  /**
   * Joins the string array into single string
   * @param {string[]} data 
   * @param {object} options
   * @returns string
   */
  static stringifyArray = (data, options = {}) => {
    let result = (data instanceof Array) ? data.join(options?.separator || "") : data

    if (options.newLineToBR) {
      result = result.replace(/\r\n/g, '<br/>')
    }

    return result
  }

  /**
   * Flattens first level of object into array of objects
   * @param {object} data 
   * @returns 
   */
  static flattenObjectIntoArray = (data) => {
    if (data && this.isObject(data)) {
      let result = []

      const keys = Object.keys(data)
      keys.forEach(key => {
        data[key].forEach(item => {
          result.push(item)
        })
      })

      return result
    }

    return data || []
  }

  /**
   * Convert deep object into single level object with keys names reflecting original levels - perfect for translation variables or similar
   * @param {object} data 
   * @param {number} level 
   * @param {string} path 
   * @returns 
   */
  static flattenObjectIntoKeyValue = (data, level = 0, path = null) => {
    if (data && this.isObject(data)) {
      let result = {}

      const keys = Object.keys(data)
      keys.forEach(key => {
        const itemPath = [path, key].filter(x => !!x).join(".")
        if (this.isObject(data[key])) {
          result = {
            ...result,
            ...this.flattenObjectIntoKeyValue(data[key], level + 1, itemPath)
          }
        } else {
          result = {
            ...result,
            [itemPath]: data[key] || "XXX"
          }
        }
      })

      return result
    }

    return { [path]: data }
  }

  /**
   * Replaces all keys with values from params array by using Moustache annotation ({{var}})
   * @param {string} string 
   * @param {any[]} params 
   * @returns string
   */
  static replaceStringParams = (string, params) => {
    if (!params) return string

    let result = string

    const keys = Object.keys(params)
    keys.forEach(key => {
      result = result.replaceAll(`{{${key}}}`, params[key])
    })

    return result
  }

  /**
   * Joins array into single string by using delimiter
   * If clearNulls is active, all blanks in array will be removed before joining
   * @param {string[]} data 
   * @param {string} delimiter 
   * @param {boolean} clearNulls 
   * @returns string
   */
  static joinArray = (data, delimiter = "", clearNulls = true) => {
    if (data instanceof Array)
      return data.filter(i => clearNulls ? i : true).join(delimiter)
    else
      return data
  }

  /**
   * Pads the string with leading zeroes until length is equal to given size
   * @param {string} num 
   * @param {integer} size 
   * @returns string
   */
  static zeroPad = (num, size) => {
    let s = num + ""
    while (s.length < size) s = "0" + s
    return s
  }

  /**
   * Simple getter for debug date
   * @returns (string)
   */
  static getDebugDate = () => {
    return DEBUG_DATE
  }

  /**
   * Return real or debug date-time
   * If debugAddOn is defined, this is how much "minutes" will be added to "now" (for debugging and testing purposes only)
   * @param {*} date 
   * @param {*} debugAddOn 
   * @returns 
   */
  static now = (date = null, debugAddOn = null) => {
    let res = dayjs()

    if (DEBUG_DATE) {
      res = dayjs(DEBUG_DATE) // global
      if (debugAddOn) {
        res = res.add(debugAddOn, "minute")
      }
    }
    if (date) res = dayjs(date) // local

    return res
  }

  static extractDateLimit = (dateLimits, ident) => {
    let dateLimit = null

    const dlKeys = ident.split(".")
    if (dlKeys.length > 0) {
      dateLimit = dateLimits // initial point
      dlKeys.forEach(dl => {
        dateLimit = dateLimit[dl]
      })
    }

    // console.debug("DLIM", ident, date_limit)

    return dateLimit
  }

  /**
   * Calculates and prepares JSON object with values important for checks if date falls into start-end date limits
   * This is needed on several places - for example if survey is available, if event is active and so on
   * @param {json} limits 
   * @returns json
   */
  static calcDateLimits = (limits) => {
    const now = this.now()

    const { from, to } = limits
    const startDate = dayjs(from)
    const endDate = dayjs(to)

    return {
      startOK: (from === null || now.isSame(startDate) || now.isAfter(startDate)),
      endOK: (to === null || now.isBefore(endDate)),
      date1: from !== null ? dayjs(startDate).format("L") : null,
      time1: from !== null ? dayjs(startDate).format("LT") : null,
      date1num: from !== null ? dayjs(startDate).format("LL") : null,
      date2: to !== null ? dayjs(endDate).format("L") : null,
      time2: to !== null ? dayjs(endDate).format("LT") : null,
      date2num: to !== null ? dayjs(endDate).format("LL") : null
    }
  }

  /**
   * Calculates event state regarding to date limits when event is being held
   * If debugAddOn is defined, this is how much minutes are added to "now" - this is important for proper debugging/time simulation
   * @param {string} startDateTime 
   * @param {string} endDateTime 
   * @param {int} debugAddOn
   * @returns json
   */
  static calcEventStatus = (startDateTime, endDateTime, debugAddOn = null) => {
    const now = this.now(null, debugAddOn)

    let result = {
      before: false,
      after: false,
      active: false
    }

    if (startDateTime && endDateTime) {
      const startOK = now.isSame(startDateTime) || now.isAfter(startDateTime)
      const endOK = now.isSame(endDateTime) || now.isBefore(endDateTime)
      result = {
        before: now.isBefore(startDateTime),
        after: now.isAfter(endDateTime),
        active: startOK && endOK
      }
    }

    return result
  }

  /**
   * Simple check if "window" is available (we are running in browser)
   * @returns boolean
   */
  static isBrowser = () => {
    return typeof window !== "undefined"
  }

  /**
   * Checks if "localStorage" is available
   * @returns boolean
   */
  static hasLocalStorage = () => {
    try {
      return (typeof localStorage === "object" && navigator.cookieEnabled)
    } catch {
      return false
    }
  }

  /**
   * Checks if light mode is activated via localStorage value or preferred user style (browser)
   * @returns boolean
   */
  static isLightMode = (config) => {
    const themeDefault = config ? config.uiFlags.themes.default : "browser"

    if (config && !config.uiFlags.themes.active) {
      if (config.uiFlags.themes.default === "browser" && this.isBrowser()) {
        const pcs = window.matchMedia("(prefers-color-scheme: dark)")
        if (typeof pcs.matches === "boolean") {
          return !pcs.matches
        }
      }

      return themeDefault === "light"
    }

    if (this.hasLocalStorage()) {
      const wlm = localStorage.getItem("weblica-light-mode")
      if (typeof wlm === "string") {
        return wlm === "true"
      }
    }

    if (this.isBrowser() && themeDefault === "browser") {
      const pcs = window.matchMedia("(prefers-color-scheme: dark)")
      if (typeof pcs.matches === "boolean") {
        return !pcs.matches
      }
    }

    return themeDefault === "light"
  }

  /**
   * Fetches a single entry, identified by given ident, from array of images returned by staticQuery (GraphQL)
   * @param {object[]} images 
   * @param {string} ident 
   * @returns object
   */
  static getImageByIdent = (images, ident) => {
    const found = images.allFile.edges.filter(item => {
      return item.node.name === ident || item.node.relativePath.indexOf(ident) !== -1
    })

    // return single image
    if (found.length > 0 && found[0].node.childrenImageSharp.length > 0)
      return found[0].node.childrenImageSharp[0].gatsbyImageData
    else
      return null
  }

  /**
   * Prepare image list from given images taken in via GraphQL and combines with images array from config or other source
   * @param {object[]} graphQLImages 
   * @param {string[]} images 
   * @returns array
   */
  static prepareImagesForGallery = (graphQLImages, images) => {
    const preparedImages = (images || []).map(img => {
      if (typeof (img) === "object") {
        return {
          title: img.title,
          ...this.getImageByIdent(graphQLImages, img.img)
        }
      } else {
        return {
          title: img,
          ...this.getImageByIdent(graphQLImages, img)
        }
      }
    })

    return preparedImages
  }

  /**
   * Fetches multiple entries, identified by path, from array of images returned by staticQuery (GraphQL)
   * @param {object[]} images 
   * @param {string} path 
   * @param {string} ignoredPath 
   * @returns object
   */
  static getMultipleImagesByRelativePath = (images, path, ignoredPath) => {
    const found = images.allFile.edges.filter(item => {
      return item.node.relativePath.indexOf(path) !== -1 && item.node.relativePath.indexOf(ignoredPath) === -1
    })
    if (found.length > 0) {
      const result = found.map(f => {
        return (f.node.childrenImageSharp.length > 0) ? f.node.childrenImageSharp[0].gatsbyImageData : null
      })
      return result
    } else
      return null
  }

  /**
   * Orders events by using "order" field (manual option in config.js)
   * @param {json} configEvents 
   * @param {json} currentEvents 
   * @param {string} key
   * @returns 
   */
  static getOrderedEventsArray = (configEvents, currentEvents, key = "order") => {
    let eventArray = []
    Object.keys(configEvents).forEach(e => {
      eventArray.push({
        "ident": configEvents[e].ident,
        "order": configEvents[e].order,
        "date": currentEvents ? currentEvents[e].startDateTime : null
      })
    })
    let sortedArray = Util.sortJSON(eventArray, key)

    let eventKeys = []
    sortedArray.forEach(e => {
      eventKeys.push(e.ident)
    })

    return eventKeys
  }

  /**
   * Fetches item from array by given index but with doing some logical and data limit checks
   * @param {any[]} data 
   * @param {integer} defaultIndex 
   * @returns object
   */
  static getObjectOrArrayItem = (data, defaultIndex = 0) => {
    if (data instanceof Array) {
      if (data.length > defaultIndex) {
        return data[defaultIndex]
      } else if (data.length > 0) {
        return data[0]
      } else {
        return null
      }
    } else {
      return data
    }
  }

  /**
   * Fetches correct language entry in "text" object
   * If there is no entry for given language, default language (English) is used
   * If default language is also not available, first array entry is returned
   * @param {json} value 
   * @param {string} lang 
   * @param {object} options
   * @returns string
   */
  static tx = (value, lang, options = { defaultLng: "en", newLineToBR: false, useFirstIfMissing: false, unknownValue: null }) => {
    if (!value) return null

    let result = value

    if (value instanceof Object) {
      if (value.hasOwnProperty(lang)) {
        result = value[lang]
      } else if (options.defaultLng && value.hasOwnProperty(options.defaultLng)) {
        result = value[options.defaultLng]
      } else if (options.useFirstIfMissing) {
        result = value[Object.keys(value)[0]]
      } else {
        result = options.unknownValue || null
      }
    }

    if (options.newLineToBR) {
      result = result.replace(/\r\n/g, '<br/>')
    }

    return result
  }

  /**
   * Joins array of classes while cleaning duplicates and blanks
   * @param {string[]} arr 
   * @returns 
   */
  static classArray = (arr) => {
    let res = Array.isArray(arr) ? arr.filter(e => !!e).join(" ") : arr
    return res.trim().replace(/  +/g, " ")
  }

  /**
   * Process given classes and use proper moduleStyle if found.
   * Only use those classes with "module." prefix - too much magic can be bad
   * If returnString is true, function will immediately use classArray function to return clean string (no need for another manual call) 
   * @param {object} moduleStyle 
   * @param {string[]} classes 
   * @param {boolean} returnString
   * @returns 
   */
  static processModuleClasses = (moduleStyle, classes, returnString = false) => {
    if (!moduleStyle) return classes

    const result = (classes || []).filter(c => c).map(c => {
      const i = Math.max((c || "").indexOf("module."), (c || "").indexOf("m."))
      if (i === 0) {
        const clean = c.replace("module.", "").replace("m.", "")
        return Object.hasOwn(moduleStyle, clean) ? moduleStyle[clean] : null
      } else {
        return c
      }
    })

    return returnString ? this.classArray(result) : result
  }

  /**
   * Combines calls of classArray with processClassModule - just a helper function
   */
  static msx = (moduleStyle, classes) => {
    if (this.isEmpty(moduleStyle) || Array.isArray(moduleStyle)) {
      console.debug("Module style needs to be an object!", moduleStyle)
      return ""
    }
    if (!Array.isArray(classes)) {
      console.debug("Classes have to be defined and in array!", classes)
      return ""
    }

    const result = this.classArray(this.processModuleClasses(moduleStyle, classes))

    // console.log("MSX:", classes, "-->", result)

    return result
  }

  /**
   * Returns number formatted by given locale
   * It can also handle currency formats (which adds currency marker)
   * @param {*} number 
   * @param {*} locale 
   * @param {*} currency 
   * @returns string
   */
  static getLocaleNumber = (number, locale, currency = null) => {
    let options = {}
    if (currency) {
      options.style = 'currency'
      options.currency = currency
    }
    return new Intl.NumberFormat(locale, options).format(number)
  }

  /**
   * Reads value from localStorage (or returns default value)
   * @param {string} item 
   * @param {any} def 
   * @returns any
   */
  static getLSItem = (item, def = null) => {
    return this.hasLocalStorage() ? localStorage.getItem(item) : def
  }

  /**
   * Writes value into localStorage
   * @param {string} item 
   * @param {any} value 
   * @returns
   */
  static setLSItem = (item, value) => {
    return this.hasLocalStorage() ? localStorage.setItem(item, value) : null
  }

  /**
   * Formats date for data blocks sent to server
   * Works without dayjs into format YYYY-MM-DD_HH_nn_ss
   * 
   * IMPORTANT - This is used only on form data and a copy of this functions is also used on server for date format consistency
   * 
   * @param {*} date 
   * @param {*} sep 
   * @returns 
   */
  static formatDateTime = (date = null, separator = "T", suffix = "Z") => {
    const d = date || new Date()
    const year = (d.getFullYear() + "").padStart(4, "0")
    const month = (d.getMonth() + "").padStart(2, "0")
    const day = (d.getDate() + "").padStart(2, "0")
    const hour = (d.getHours() + "").padStart(2, "0")
    const minute = (d.getMinutes() + "").padStart(2, "0")
    const second = (d.getSeconds() + "").padStart(2, "0")
    return `${year}-${month}-${day}${separator}${hour}-${minute}-${second}${suffix}`
  }

  /**
   * Return lang identifier if ML is active, otherwise return "." to have working links
   * @param {string} lang 
   * @param {object} config 
   * @returns 
   */
  static getLinkLang = (lang, config) => {
    return config?.multiLanguage?.active ? lang : "."
  }

  /**
   * Prepares fixes set of parameters used in translation functions (for variable replacements)
   * @param {string} event 
   * @param {string} lang 
   * @param {json} config 
   * @param {json} current 
   * @returns json
   */
  static prepDefaultParamsForTexts = (event, lang, config, current, extra = {}) => {
    if (!config || Object.keys(config).length === 0) {
      return {}
    }
    if (!current || Object.keys(current).length === 0) {
      return {}
    }

    const configEvent = Util.getEventConfig(event, config, current)
    const currentEvent = current.events[event]

    const displayVenue = Util.getObjectOrArrayItem(Util.prepareVenueData(current, currentEvent.venue))

    let venueName = "?", venueAddress = "?", venueNameAndAddress = "?"
    if (displayVenue?.name) {
      venueName = Util.tx(displayVenue?.name, lang)
      venueAddress = `${displayVenue?.street}, ${displayVenue?.postalCode} ${displayVenue?.city}`
      venueNameAndAddress = Util.joinArray([
        Util.tx(displayVenue?.name, lang),
        `${displayVenue?.street}, ${displayVenue?.postalCode} ${displayVenue?.city}`
      ], " - ")
    }

    const mapUrl = displayVenue?.mapUrl || ""

    let dateLimits = {}
    try {
      const tmpDateLimits = this.flattenObjectIntoKeyValue(current.dateLimits)
      Object.keys(tmpDateLimits).forEach(key => {
        dateLimits[key] = tmpDateLimits[key] ? dayjs(tmpDateLimits[key]).format("L") : null
      })
    } catch (e) {
      console.log(e)
    }

    const defaultParams = {
      lang: lang,
      linkLang: config?.multiLanguage?.active ? lang : ".",
      currentYear: current.currentYear,
      event: event,
      eventName: Util.tx(configEvent.title, lang),
      eventDate: dayjs(currentEvent.startDateTime).format("L"),
      eventTime: dayjs(currentEvent.startDateTime).format("LT"),
      venueName: venueName,
      venueAddress: venueAddress,
      venueNameAndAddress: venueNameAndAddress,
      mapUrl: mapUrl,
      ticketsUrl: (currentEvent.externalLinks || {}).ticketsUrl,
      sessionsUrl: (currentEvent.externalLinks || {}).sessions,
      urlPrefix: withPrefix("."),
      ...dateLimits,
      ...extra
    }

    return defaultParams
  }

  /**
   * Generates random number between min & max values
   * @param {integer} min 
   * @param {integer} max 
   * @returns integer
   */
  static getRandomInt = (min, max) => {
    min = Math.ceil(min)
    max = Math.floor(max)
    return Math.floor(Math.random() * (max - min + 1)) + min
  }

  /**
   * Shuffles array (random order) - NOT USED
   * @param {any[]} arr 
   * @returns 
   */
  static shuffleArray = (arr) => {
    for (var c = arr.length - 1; c > 0; c--) {
      var b = Math.floor(Math.random() * (c + 1));
      var a = arr[c];
      arr[c] = arr[b];
      arr[b] = a;
    }
    return arr
  }

  /**
   * Extract host part of the URL string
   * Works only in browser - intentionally does not have try/catch block
   * @param {string} urlString 
   * @returns string
   */
  static extractHost = (urlString) => {
    if (!urlString) return urlString

    var url = new URL(urlString)
    return url.hostname
  }

  /**
   * Removes double slashes from string
   * Mostly used for URL cleanup when concatenation is done
   * @param {string} str 
   * @returns string
   */
  static replaceDoubleSlashes = (str) => {
    return str.replace(/\/\//g, "/")
  }

  /**
   * Removes leading slash - used to avoid double slashes
   * @param {string} str 
   * @returns string
   */
  static removeLeadingSlash = (str) => {
    return str.indexOf("/") === 0 ? str.substring(1) : str
  }

  /**
   * Empties the string if it contains only the slash
   * @param {string} str 
   * @returns string
   */
  static removeSlashOnly = (str) => {
    return str === "/" ? "" : str
  }

  /**
   * Get first available identifier - this is used to get general translation, and if not found, local (by given prefixes)
   * @param {object} i18n 
   * @param {string} ident 
   * @param {string[]} prefixes (groups)
   * @returns string
   *
   * Usage:
   * Util.getTransIdent(i18n, ident, ["pages.sponsors", "general"])
   */
  static getTransIdent = (i18n, ident, prefixes = ["general"]) => {
    for (let i = 0; i < prefixes.length; i++) {
      const key = `${prefixes[i]}.${ident}`
      if (i18n.exists(key)) {
        return key
      }
    }
    return null
  }

  /**
   * Replaces local characters with ASCII ones to keep slugs properly formatted
   * @param {string} str 
   * @param {integer} maxLength 
   * @param {string} suffix 
   * @returns string
   */
  static slugify = (str, maxLength = null, suffix = null) => {
    str = str.replace(/^\s+|\s+$/g, '') // trim

    if (maxLength) {
      if (str.length > maxLength) {
        str = str.substring(0, maxLength)
      }
    }

    if (suffix) {
      str = str + "-" + suffix
    }

    str = str.toLowerCase()

    // replace special chars - but important to mark
    str = str.replace(/\+/g, "-plus")

    // remove accents, swap ñ for n, etc
    var from = "àáäâèéëêìíïîòóöôùúüûñçšđčćž·/_,:;"
    var to = "aaaaeeeeiiiioooouuuuncsdccz------"
    for (var i = 0, l = from.length; i < l; i++) {
      str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i))
    }

    str = str.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
      .replace(/\s+/g, '-') // collapse whitespace and replace by -
      .replace(/-+/g, '-') // collapse dashes

    return str
  }

  // static getValidFirstItem = (value) => { // NOT USED
  //   if (!Array.isArray(value)) return value
  //   if (value.length === 0) return null

  //   return value[0]
  // }

  static isObject = (value) => {
    return (typeof value === "object")
  }

  /**
 * Generates a list of diff object with differences between two given objects
 * 
 * @param {object} obj1 
 * @param {object} obj2 
 * @param {string[]} usedFields - what fields are used (null if all)
 * @param {string[]} ignoredFields - what field are ignores (null is none)
 * @param {string} diffType - "list" = only keys are returned, "diff" = full diff object (old vs new values for each keys) is returned 
 * @param {int} level 
 * @param {boolean} recursion - recursion allowed or not
 * @returns array | object
 */
  static objectDiff = (obj1, obj2, usedFields = null, ignoredFields = null, diffType = "list", level = 0, recursion = true, nullAsDefault = true) => {
    if (!obj1 || !obj2) return null

    let result = diffType === "list" ? [] : {}

    const obj1Props = Object.getOwnPropertyNames(obj1)
    const obj2Props = Object.getOwnPropertyNames(obj2)
    const props = Array.from(new Set([...obj1Props, ...obj2Props]))

    props.forEach(key => {
      const isIncluded = (usedFields === null || usedFields.includes(key))
      const isIgnored = (ignoredFields !== null && ignoredFields.includes(key))

      if (isIncluded && !isIgnored) {
        if (
          (recursion && diffType === "diff")
          &&
          (
            (obj1[key] && typeof obj1[key] === "object")
            ||
            (obj2[key] && typeof obj2[key] === "object")
          )
        ) { // recursive call, if possible
          const diff = this.objectDiff(obj1[key] || null, obj2[key] || null, usedFields, ignoredFields, diffType, level + 1, recursion)
          if (diff && diff.hasProperties) result[key] = diff
        } else {
          const check1 = nullAsDefault ? obj1[key] || null : obj1[key]
          const check2 = nullAsDefault ? obj2[key] || null : obj2[key]

          const s1 = JSON.stringify(check1)
          const s2 = JSON.stringify(check2)

          const isDifferent = s1 !== s2

          if (isDifferent) {
            if (diffType === "list") {
              result.push(key)
            } else if (diffType === "diff") {
              result[key] = {
                old: obj1[key],
                new: obj2[key],
              }
            }
          }
        }
      }
    })

    return result
  }

  /**
   * Returns combined config for given event from config and from current settings
   * This is useful since it enables config default to be overridden in current event settings
   * @param {string} event 
   * @param {object} config 
   * @param {object} current 
   * @param {object} options 
   * @returns 
   */
  static getEventConfig = (event, config, current, options = { ignoreMissingCurrent: false }) => {
    // console.debug("getEventConfig", { event, config, current, options })

    if (!config || !(Object.hasOwn(config?.events, event)))
      throw `Can not get config for unknown event: ${event}`

    if (!options.ignoreMissingCurrent && (!current || !(Object.hasOwn(current?.events, event))))
      throw `Can not get current config for unknown event: ${event}`

    return {
      ...config.events[event],
      ...(!this.isEmpty(current) ? current.events[event].config || {} : {})
    }
  }

  /**
   * Scrolls to given hash identifier
   * Also takes care of expanding SectionBox if existing and collapsed
   * @param {*} document 
   * @param {*} hash 
   */
  static scrollHashToView = (document, hash, setCtxHashFunc) => {
    const __findAncestor = (el, cls) => {
      while ((el = el.parentElement) && !el.classList.contains(cls));
      return el;
    }

    if (hash.charAt(0) === "#") hash = hash.substring(1, hash.length)
    if (hash) {
      const el = document.getElementById(hash)
      if (el) {
        setTimeout(() => {
          // if section is collapsed, expand it first
          const section = !el.classList.contains("section") ? __findAncestor(el, "section") : el
          if (section) {
            const handle = section.querySelector(".handle")
            const handleVisible = handle && window.getComputedStyle(handle).display === "block"
            if (handle && handleVisible) {
              if (setCtxHashFunc) {
                setCtxHashFunc(hash)
              }
            }
  
            el.scrollIntoView() // TODO-IGNORE: some odd rendering bug happens when jumping to some inner element
          }
        }, 50)
      }
    }
  }

  /**
   * Return value if check is true
   * @param {boolean} check 
   * @param {any} value 
   * @returns 
   */
  static valueOrNull = (check, value) => {
    return check ? value : null
  }

  /**
   * Return array of values for all checks that are true
   * 
   * Parameter needs to be array in array - like this:
   *  [
   *    [active, moduleStyle.active],
   *    [hollow, moduleStyle.hollow],
   *    [special, moduleStyle.special],
   *    [external, moduleStyle.external]
   *  ]
   * 
   * @param {any[]} arr
   * @returns 
   */
  static valuesOrNulls = (arr) => {
    if (!Array.isArray(arr)) return []

    let result = []
    arr.forEach(item => {
      if (Array.isArray(item)) {
        const check = item[0]
        const value = item[1]
        if (check) result.push(value)
      }
    })
    return result
  }

  /**
   * Return venue data - either if it is a direct object or identifier (then take the data from venues sub-element)
   * 
   * @param {object} current - used to access "global" venues data used for easy reuse
   * @param {object} data - specific event venue (with full data or only with identifier to access "global" venue items)
   * @returns 
   */
  static prepareVenueData = (current, data) => {
    if (this.isEmpty(data)) return null
    if (!Object.hasOwn(current, "venues")) return data

    if (Array.isArray(data)) {
      let result = []
      data.forEach(vv => {
        if (vv.venue) {
          const tmp = current.venues.find(v => v.ident === vv.venue)
          if (tmp) result.push(tmp)
        } else {
          return vv
        }
      })
      return result
    } else if (data) {
      if (data.venue) {
        return current.venues.find(v => v.ident === data.venue)
      } else {
        return data
      }
    }
  }

  static getExtraContent = (data, location, onlyValidDates = true) => {
    if (this.isEmpty(data)) return null

    const result = data.filter(item => {
      if (onlyValidDates) {
        const dl = Util.calcDateLimits(item)
        return location === item.location && dl.startOK && dl.endOK
      } else {
        return location === item.location
      }
    })

    return result
  }

  static _rgba2hex = (rgba) => {
    if (this.isEmpty(rgba)) return null

    return `#${rgba.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+\.{0,1}\d*))?\)$/).slice(1).map((n, i) => (i === 3 ? Math.round(parseFloat(n) * 255) : parseFloat(n)).toString(16).padStart(2, '0').replace('NaN', '')).join('')}`
  }

  static _getRGB = (colorString) => {
    if (!this.isBrowser() || this.isEmpty(colorString)) return null

    var elem = document.createElement("div")
    elem.style.display = "none"
    elem.style.color = colorString
    document.body.appendChild(elem)

    return window.getComputedStyle(elem, null).getPropertyValue("color")
  }

}

export default Util